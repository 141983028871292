import styled from 'styled-components';

const ButtonNormal = styled.button`
  font-size: 0.9rem;
  font-weight: 400;
  box-sizing: border-box;
  color: white;
  background-color: #91a7d0;
  padding: 0.3rem;
  border-radius: 0.4rem;
  border: none;
  cursor: pointer;
  display: block;
  &:hover {
    background-color: #a1bce9;
  }
`;

export {
  ButtonNormal
}