import React from 'react';
import styled from 'styled-components';
import { WineBottle} from '@styled-icons/fa-solid/WineBottle';
import {rotate} from "./Animations";

const LoadingIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  z-index: 110;
`;

const WineIcon = styled(WineBottle)`
  width: 10vh;
  height: 100%;
  color: #DC4261;
  animation: ${rotate} 2s linear;
`;

const LoadingIcon = () => {
  return (
    <LoadingIconWrapper>
      <WineIcon/>
    </LoadingIconWrapper>
  )
};

export default LoadingIcon;