import React, {useState, useRef } from 'react';
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { SortDown } from '@styled-icons/boxicons-regular/SortDown';
import { SortUp } from '@styled-icons/boxicons-regular/SortUp';
import {useSearch} from "./helpers";
import {Select} from "../common/Inputs";

const SorterContainer = styled.form`
  display: flex;
  flex-direction: row;
  font-weight: 300;
`;

const OrderContainer = styled.div`
  display: flex;
  margin: 0.5em;
  /* box-shadow: 0 0.1em 0.15em rgba(0,0,0,0.3); */
  border-radius: 0.2em;
  border-bottom: solid 0.2em #DC4261;
  overflow: hidden;
  & > label:first-of-type {
    border-right: solid 0.1em lightgrey;
  }
  & > label {
    display: flex;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    flex: 1;
    padding: 0.1em 1.0em;
    color: #3f3f3f;
    transition: 0.2s linear;
    border: solid 0.15em #DC4261;
    box-sizing: border-box;
  }
  & > label:hover {
    background-color: #f5f5f5; 
  }
  & > input:checked+label {
    box-shadow: inset 0 0.2em 0.34em rgba(0,0,0,0.25);
    background-color: #DC4261;
    color: white;
    border-color: transparent;
  }
  &  > input {
    display: none;
    visibility: hidden;
  }
`;

const SortLabel = styled.div`
  margin: 0 0.5em;
`;

const IcnSortDown = styled(SortDown)`
  color: inherit;
  height: 1.3em;
  margin: 0;
`;
const IcnSortUp = styled(SortUp)`
  color: inherit;
  height: 1.3em;
  margin: 0;
`;

const Sorter = ({searcher}) => {
  const { searchParams, handleChange } = searcher;
  return (
    <SorterContainer>
      <SortLabel>
        <p>Sorter etter:</p>
      </SortLabel>
      <Select name="sortBy" id="selectSort" value={searchParams.sortBy} onChange={handleChange}>
        <option value="price">Pris</option>
        <option value="apk">Etanol ml/kr</option>
        <option value="name">Navn</option>
        <option value="alcohol">Vol %</option>
      </Select>
      <OrderContainer>
        <input type="radio" id="radioAsc" name="sortDir" value="asc"
               checked={searchParams.sortDir === 'asc'} onChange={handleChange} />
        <label htmlFor="radioAsc">
          <IcnSortUp />
        </label>
        <input type="radio" id="radioDesc" name="sortDir" value="desc"
               checked={searchParams.sortDir === 'desc'} onChange={handleChange} />
        <label htmlFor="radioDesc">
          <IcnSortDown />
        </label>
      </OrderContainer>
    </SorterContainer>
  );
};

export default Sorter;