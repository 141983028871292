import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost:3000/api',
  baseURL: 'https://eliasv.com/alkomax/api/',
});

export const getDrinksPage = (page, query) => api.get(`/drinks/${query}/${page}`);
export const getDrinkById = id => api.get(`/drink/${id}`);
/*
export const deleteDrinkById = id => api.delete(`/drink/${id}`);
export const insertDrink = payload => api.post(`/drink`, payload);
export const getAllDrinks = () => api.get(`/drinks`);
export const updateDrinkById = (id, payload) => api.put(`/drink/${id}`, payload);
export const getHello = () => api.get('/hello');
export const insertDrinks = payload => api.post('/drinks', payload);
export const deleteAll = payload => api.post('/drinks/delete-all', payload);
*/

const apis = {
  getDrinkById,
  getDrinksPage,
/*  insertDrink,
  getAllDrinks,
  updateDrinkById,
  deleteDrinkById,
  getHello,
  insertDrinks,
  deleteAll,*/
};

export default apis