import {useLocation} from "react-router";
import {useHistory} from "react-router-dom";
import queryString from "query-string";
import {useEffect, useState} from "react";
import { getAllStoreNames } from '../../models/stores';
import api from "../../api";

const DEFAULT_SEARCH_PARAMS = {
  name: "",
  sortBy: "name",
  sortDir: "asc",
  priceMin: 0,
  priceMax: 50,
  categories: [],
  stores: getAllStoreNames(),
  onlyinstock: true,
  page: 1,
};

export const useSearch = (initialSearchParams=null) => {
  const DEFAULT_RESULTS = {
    docs: [],
    page: 1,
    totalPages: 1,
    totalDocs: 0,
    hasNextPage: false,
    hasPrevPage: false,
  };

  const { search } = useLocation();
  const history = useHistory();

  const getParamsFromUrl = () => {
    const parsedParams = queryString.parse(search);
    if (!Array.isArray(parsedParams.categories)) {
      parsedParams.categories = !!parsedParams.categories ? [parsedParams.categories] : [];
    }
    if (!Array.isArray(parsedParams.stores)) {
      parsedParams.stores = !!parsedParams.stores ? [parsedParams.stores] : [];
    }
    return {...DEFAULT_SEARCH_PARAMS, ...parsedParams};
  };

  const [ searchParamsAll, setSearchParamsAll ] = useState( initialSearchParams ? initialSearchParams : getParamsFromUrl());
  const [ results, setResults ] = useState(DEFAULT_RESULTS);
  const [ loading, setLoading ] = useState(false);
  const [ changed, setChanged ] = useState(0);

  const loadResults = async () => {
    setLoading(true);
    let translated = translateValues(queryString.parse(search));
    let qs = queryString.stringify(translated);
    try {
      await api.getDrinksPage(translated.page-1, qs).then((res) => {
        const { data } = res;
        if (data.success) {
          setResults(data.data);
          if (data.data.totalPages < searchParamsAll.page) {
            setSearchParams({page: 1});
            loadResults();
          }
        }
      });
    } catch (err) {
      setResults(DEFAULT_RESULTS);
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    let translated = queryString.parse(search);
    let qs = queryString.stringify(translated);
    if (!qs) {
      history.push('?' + queryString.stringify(DEFAULT_SEARCH_PARAMS));
      return;
    }
    if (!loading) {
      setChanged(0);
      loadResults();
    } else {
      setChanged(changed + 1);
      const waitAndTry = () => {
        if (changed === 1 && !loading) {
          setChanged(0);
          loadResults();
        }
      }
      setTimeout(waitAndTry, 1000);
    }

  }, [search]);


  const setSearchParams = (params) => {
    Object.entries(params).forEach(([key, val]) => {
      if (key === 'priceMin' && parseInt(val) >= searchParamsAll.priceMax) {
        params[key] = Math.max(0, Math.min(searchParamsAll.priceMax - 1, parseInt(val)));
      }
      if (key === 'priceMax' &&  parseInt(val) <= searchParamsAll.priceMin) {
        params[key] = Math.max(1, Math.min(searchParamsAll.priceMin + 1, parseInt(val)));
      }
      if (key === 'page' && parseInt(val) <= 0) {
        params[key] = 1;
      }
    });
    if (Object.entries(params).filter(([key, val]) => key === 'page').length === 0) {
      Object.assign(params, {page: 1});
    }
    const newSearchParams = { ...searchParamsAll, ...params};
    setSearchParamsAll(newSearchParams);
    history.push('?' + queryString.stringify(newSearchParams));
  };

  const reset = () => {
    setSearchParams(DEFAULT_SEARCH_PARAMS);
  };

  const handleChange = ({target}) => {
    setSearchParams({[target.name]: target.value});
  };

  return {
    searchParams: searchParamsAll,
    setSearchParams,
    reset,
    handleChange,
    results,
    loading,
  };
};

export const translateValues = (values) => {
  return Object.entries(values).reduce((a, [name, val]) => {
    if (name === 'priceMin' || name === 'priceMax') {
      if (val >= 50) {
        val = Infinity;
      } else {
        val = Math.pow(val, 3);
      }
    }
    return {...a, ...{[name]: val}};
  }, {});
};

export const device = {
  mobileS: `(max-width: 320px)`,
  mobileM: `(max-width: 375px)`,
  mobileL: `(max-width: 425px)`,
  tablet: `(max-width: 768px)`,
};
