import { keyframes } from 'styled-components';
const zoomIn = keyframes`
  
  0% {
    opacity: 0.0;
    transform: scale(0.2);
  }
  
  70% {
    opacity: 0.0;
  }
  90% {
    opacity: 0.6;
    transform: scale(1);
  }
  100% {
    opacity: 1.0;
    transform: scale(1);
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
export {
  zoomIn,
  rotate,
}