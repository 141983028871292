import React from 'react';
import styled from 'styled-components';
import {Card} from "../common/Containers";
import {NormalLink, Article} from "../common/Misc";
import VippsButton from "./VippsButton";

const AboutWrapper = styled.div`

`;

const About = () => {
  return (
    <Card>
      <Article>
        <h1>Litt om nettsiden</h1>
        <br/>
        <h2>Hva er AlkoMax?</h2>
        <p>
          <i>AlkoMax</i> er et fritidsprosjekt laget av meg, Elias. Her kan du søke og
          filtrere blant produkter fra Vinmonopolet og Systembolaget. Hovedmålet med
          prosjektet var opprinnelig å enklere oppdage drikker som gir deg mest mulig
          alkohol for pengene.
        </p>
        <p>
          <b>NB!</b> <i>AlkoMax</i> er ikke laget i samarbeid med Vinmonopolet eller Systembolaget.
          All data på dette nettstedet er hentet gjennom deres åpne API-er, og er lagret i
          en separat database som kalles med mitt eget API.
        </p>
        <h2>Donasjoner</h2>
        <p>Jeg betaler ut av egen lomme for denne nettsiden. Hvis du har lyst til å kjøpe meg en pils,
          så tar jeg vipps.</p>
        <VippsButton />
        <h2>Kontakt</h2>
        <p>
          For kritikk, ros eller andre hendvendelser, send meg gjerne en mail
          på <NormalLink href="mailto:admin@alkomax.no">admin@alkomax.no</NormalLink>.
        </p>
      </Article>
    </Card>
  )
};

export default About;