import React, { useState } from 'react';
import styled from 'styled-components';
import {ButtonNormal} from "../common/Buttons";
import categories from "../../models/categories";
import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown';
import { ArrowDropUp } from '@styled-icons/material/ArrowDropUp';
import CategoryFilter from "./CategoryFilter";
import Requirements from "./Requirements";
import {device, translateValues, useSearch} from "./helpers";
import {StorePicker} from "./StorePicker";
import {Range, TextInput} from "../common/Inputs";

const SearchFormWrapper = styled.div`
  margin: 0.5rem 0 0.5em 0.5em;
  background-color: white;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
  border-radius: 0.3em 0 0.3em 0.3em;
  font-weight: 300;
  box-sizing: border-box;
  @media ${device.mobileL} {
    margin: 1.5em; 
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  & > div {
    margin: 0.5em;
  }
`;

const ResetButton = styled(ButtonNormal)`
  color: darkred;
  background-color: #ff8f9a;
  margin: 0 0.5em 0.5em 0.5em;
  border-radius: 0.2em;
  &:hover {
    background-color: #ffb6ba;
  }
`;

const SearchForm = ({searcher}) => {

  const { searchParams, handleChange, reset } = searcher;

  return (
    <SearchFormWrapper>
      <InputsWrapper>
        <div>
          <TextInput type="text" id="inpName" name="name" onChange={handleChange} value={searchParams.name} placeholder="Søk i tekst"/>
        </div>
        <div>
          <label htmlFor="inpPriceMin" >Min. pris: {translateValues(searchParams).priceMin},-</label>
          <Range id="inpPriceMin" step="1" onChange={handleChange}
                 value={searchParams.priceMin} name="priceMin" min="0"
                 max={searchParams.priceMax -1} />
          <label htmlFor="inpPriceMax">Maks. pris: {translateValues(searchParams).priceMax},-</label>
          <Range id="inpPriceMax" step="1" onChange={handleChange}
                 value={searchParams.priceMax} name="priceMax"
                 min={searchParams.priceMin} max="50" />
        </div>
        <div>
          <StorePicker searcher={searcher}/>
        </div>
        <div>
          <Requirements searcher={searcher}/>
        </div>
        <div>
          <CategoryFilter searcher={searcher}/>
        </div>
        <ResetButton onClick={() => reset()}>Nullstill søk</ResetButton>
      </InputsWrapper>
    </SearchFormWrapper>
  )
};

export default SearchForm;