import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import BackgroundPattern from '../res/bg/sun-pattern.png';
import { Header, Footer, DrinkSearch, Leaderboard } from '../components'
import About from "../components/About";
import {device} from "../components/DrinkSearch/helpers";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100.1vh;
  /* background-image: url(${BackgroundPattern});
  background-repeat: repeat;
  background-attachment: scroll; */
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  background-position: top left; 
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  @media ${device.mobileL} {
    font-size: 13px;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  flex: 1;
`;

const  App = () => {

  return (
    <Router>
      <AppWrapper>
        <Header />
        <SwitchWrapper>
          <Switch>
            <Route path="/search" component={DrinkSearch}/>
            <Route path="/leaderboard" exact component={Leaderboard}/>
            <Route path="/about" exact component={About}/>
            { /*  <Route path="/new-single" component={NewDrink}/> */ }
            { /*  <Route path="/new-multiple" component={NewDrinks}/> */ }
            { /*  <Route path='/api-test' component={ApiTest} /> */ }
            { /*  <Route path="/control-panel" component={ControlPanel}/> */ }
            { /*  <Route path="/login" component={Login}/> */ }
            <Route path="/" component={() => <Redirect to="/search" /> }/>
          </Switch>
        </SwitchWrapper>
        <Footer/>
      </AppWrapper>
    </Router>
  )
}

export default App