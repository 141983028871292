const categories = {
  'rodvin': {
    name: 'rodvin',
    value: 'Rødvin',
    color: '#b01a4e',
  },
  'hvitvin': {
    name: 'hvitvin',
    value: 'Hvitvin',
    color: '#b8a580',
  },
  'rosevin': {
    name: 'rosevin',
    value: 'Rosévin',
    color: '#c475ca',
  },
  'musserendevin': {
    name: 'musserendevin',
    value: 'Musserende vin',
    color: '#9eac60',
  },
  'ol': {
    name: 'ol',
    value: 'Øl',
    color: '#f19844',
  },
  'brennevin': {
    name: 'brennevin',
    value: 'Brennevin',
    color: '#3174e5',
  },
  'sterkvin': {
    name: 'sterkvin',
    value: 'Sterkvin',
    color: '#731e1e',
  },
  'aperitif': {
    name: 'aperitif',
    value: 'Aperitif',
    color: '#cb6231',
  },
  'portvin': {
    name: 'portvin',
    value: 'Portvin',
    color: '#bf2222',
  },
  'glogg': {
    name: 'glogg',
    value: 'Gløgg',
    color: '#a2102f',
  },
  'likor': {
    name: 'likor',
    value: 'Likør',
    color: '#5cb4be'
  },
  'rom': {
    name: 'rom',
    value: 'Rom',
    color: '#924027'
  },
  'akevitt': {
    name: 'akevitt',
    value: 'Akevitt',
    color: '#506765'
  },
  'vodka': {
    name: 'vodka',
    value: 'Vodka',
    color: '#757575'
  },
  'whisky': {
    name: 'whisky',
    value: 'Whisky',
    color: '#8c5d07'
  },
  'gin': {
    name: 'gin',
    value: 'Gin',
    color: '#603985'
  },
  'bitter': {
    name: 'bitter',
    value: 'Bitter',
    color: '#67544e'
  },
  'alkoholfritt': {
    name: 'alkoholfritt',
    value: 'Alkoholfritt',
    color: '#41bf42',
  },
  'perlendevin': {
    name: 'perlendevin',
    value: 'Perlende vin',
    color: '#96ac57',
  },
  'fruktvin': {
    name: 'fruktvin',
    value: 'Fruktvin',
    color: '#b86a6a',
  },
  'sake': {
    name: 'sake',
    value: 'Sake',
    color: '#769f68',
  },
  'aromatisertvin': {
    name: 'aromatisertvin',
    value: 'Aromatisert vin',
    color: '#c35151',
  },
  'mjod': {
    name: 'mjod',
    value: 'Mjød',
    color: '#7a491f',
  },
  'sider': {
    name: 'sider',
    value: 'Sider',
    color: '#cdb50b',
  }
};
const FALLBACK_CATEGORY = {
  value: 'Ukjent kategori',
  color: '#515151',
};

export default categories;
export { FALLBACK_CATEGORY };