import React from 'react';
import styled from 'styled-components';
import allStores from '../../models/stores';
import { Store } from '@styled-icons/boxicons-regular/Store';
import {CheckBox} from "../common/Inputs";
import {WeakBox} from "../common/Containers";

const StoreIcon = styled(Store)`
  color: inherit;
  height: 1.2em;
  margin-left: 0.5em;
`;

const StorePicker = ({searcher}) => {
  const { searchParams, setSearchParams } = searcher;
  const { stores } = searchParams;
  const handleChange = ({target}) => {
    if (stores.includes(target.name)) {
      setSearchParams({stores: stores.filter(s => s !== target.name)});
    } else {
      setSearchParams({stores: [...stores, target.name]});
    }
  }
  return (
    <WeakBox text="Velg butikk" icon={<StoreIcon/>}>
      <table>
        <tbody>
        { Object.entries(allStores)
          .filter(([key, s]) => key !== 'default')
          .map(([key, { name, value}]) => (
          <tr key={key}>
            <td>
              <label htmlFor={`${name}Checker`}>
                <p>{value}</p>
              </label>
            </td>
            <td>
              <CheckBox name={name} checked={stores.includes(name)} id={`${name}Checker`} onChange={handleChange}/>
            </td>
          </tr>
          ))}
        </tbody>
      </table>
    </WeakBox>
  )
};

export {StorePicker};