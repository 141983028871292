import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from "react-router-dom";
import { Search as IconSearch } from  '@styled-icons/boxicons-regular/Search';
// import { Upload as IconUpload} from '@styled-icons/boxicons-regular/Upload';
// import { WineBottle as IconBottle } from '@styled-icons/fa-solid/WineBottle';
import { AccountCircle as IconAccount } from '@styled-icons/material/AccountCircle';
// import { ShieldQuarter as IconShield } from '@styled-icons/boxicons-regular/ShieldQuarter';
import { InfoOutline as IconAbout } from '@styled-icons/evaicons-outline/InfoOutline';
import { Star as IconStar } from '@styled-icons/boxicons-regular/Star';
import logo1 from '../res/logos/logo1.svg';
import {device} from "./DrinkSearch/helpers";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0.1rem 0.2rem rgba(0,0,0,0.1);
`;
const Logo = styled.img`
  height: 5em;
  margin: 1em;
  display: inline-block;
  transition: 0.1s;
  &:hover {
    transform: scale(1.05);
  }
`;
const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  outline: none;
`;
const HeaderNav = styled.nav`
  position: relative;
  margin: 0 auto;
  min-width: 50vw;
  max-width: 90vw;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  font-weight: 300;
`;
const HeaderLinkWrapper = styled(Link)`
  flex: 1;
  padding: 0.6rem;
  max-width: 16em;
  color: ${ (props) => props.active ? '#2f4858' : 'inherit'};
  border-bottom: solid 0.2rem;
  border-color: ${ (props) => props.active ? '#2f4858' : 'transparent'};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  outline: none;
  & > p {
    display: inline;
    margin: 0;
    font-size: 1.1em;
  }
  &:hover {
    background-color: #f5f5f5;
    color: #2f4858;
  }
`;
const HeaderIconWrapper = styled.div`
  width: 1.3rem;
  height: 100%;
  margin: 0 0.6rem 0 0;
  color: inherit;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  & > svg {
    color: inherit;
    height: 100%;
    width: 100%;
  }
`;

const AccountLinkWrapper = styled.div`
  position: relative;
  padding: 0.6rem 1.2em;
  &:hover {
    background-color: #f5f5f5;
    color: #91a7d0;
  }
`;
const AccountDropdown = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 0.2em 0.2em rgba(0,0,0,0.3);
`;
const DropdownLink = styled(Link)`
  padding: 0.5em 1.0em;
  color: black;
  text-decoration: none;
  &:hover {
    background-color: #f3f3f3
  }
`;


const HeaderLink = ({ to, text, icon = null }) => {
  const { pathname } = useLocation();
  return (
    <HeaderLinkWrapper to={to} active={pathname.startsWith(to)} >
      <HeaderIconWrapper>
        {icon}
      </HeaderIconWrapper>
      <p>{text}</p>
    </HeaderLinkWrapper>
  )
};

/* const AccountLink = () => {
  const [ collapsed, setCollapsed ] = useState(false);
  return (
    <AccountLinkWrapper onClick={() => setCollapsed(!collapsed)}>
      <HeaderIconWrapper>
        <IconAccount />
      </HeaderIconWrapper>
      { collapsed ? (
        <AccountDropdown>
          <DropdownLink to="/login">Logg inn</DropdownLink>
          <DropdownLink to="/control-panel">Kontrollpanel</DropdownLink>
        </AccountDropdown>
      ) : null }
    </AccountLinkWrapper>
  );
}; */

const Header = () => {
  return (
    <HeaderContainer>
      <LogoLink to="/search">
        <Logo src={logo1}/>
      </LogoLink>
      <HeaderNav>
        <HeaderLink to="/search" text="Søk" icon={<IconSearch />} />
        <HeaderLink to="/leaderboard" text="Topplista" icon={<IconStar />} />
        <HeaderLink to="/about" text="Diverse" icon={<IconAbout />} />
        { /* <AccountLink /> */ }
      </HeaderNav>
    </HeaderContainer>
  );
};

export default Header;