import VMlogo from '../res/logos/vinmonopolet.png';
import SBlogo from '../res/logos/systembolaget.svg';

const stores = {
  vinmonopolet: {
    name: 'vinmonopolet',
    value: 'Vinmonopolet',
    logo: VMlogo,
  },
  systembolaget: {
    name: 'systembolaget',
    value: 'Systembolaget',
    logo: SBlogo,
  },
  default : {
    name: 'ukjent',
    value: 'Ukjent butikk',
    logo: null,
  },
};

const getAllStoreNames = () => {
  return Object.entries(stores).filter(([key, s]) => key !== 'default').map(([key, s ]) => key)
}

export default stores;
export { getAllStoreNames }
