import React from 'react';
import styled from 'styled-components';
import categories, { FALLBACK_CATEGORY } from "../../models/categories";
import stores from '../../models/stores';
import {backInUp, zoomIn} from "../common/Animations";



const DrinkResultContainer = styled.div`
  width: 14em;
  flex-grow: 1;
  background-color: white;
  margin: 0.25rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.1rem 0.2rem rgba(0,0,0,0.1);
  border: solid 0.2em ${(props) => props.color};
  border-radius: 0.2em;
  box-sizing: border-box;
  animation: ${zoomIn} 0.8s ease;
  overflow: hidden;
`;
const PriceTag = styled.div`
  position: absolute;
  top: 0;
  right: 0.5rem;
  display: inline;
  color: #585858;
  font-weight: 300;
  font-size: 1.1rem;
  background-color: white;
`;

const ImageWrapper = styled.div`
  margin-top: 0.5em;
  height: 10rem;
  background-image: url(${props => props.image}), url('https://bilder.vinmonopolet.no/bottle.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
`;

const Description = styled.div`
  padding: 0.5rem;
  & > a {
    display: block;
    text-align: center;
    color: inherit;
    text-decoration: none;
    font-weight: 500;
  }
  & > a:hover {
    text-decoration: underline;
  }
`;
const Taste = styled.p`
  font-weight: 300;
  font-size: 0.9em;
  color: dimgray;
`;
const Info = styled.div`
  display: flex;
  flex-direction: row;
  & > p {
    flex: 1;
    text-align: center;
  }
`;

const CategoryTagContainer = styled.div`
  background-color: ${(props) => props.color};
  color: white;
  font-size: 0.9em;
  border-radius: 0 0 0.5em 0;
  display: inline;
  position: absolute;
  top: -0.6em;
  left: 0;
  font-weight: 300;
  padding: 0 0.4em 0.1em 0.2em;
  margin: 0;
`;

const ValueBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 1.0em;
  height: 5.0em;
  position: absolute;
  top: 1.5em;
  left: 0.5em;
  box-sizing: border-box;
  padding: 0.1em;
  overflow: hidden;
  border: solid 0.2em lightgrey;
  border-radius: 2em;
`;
const FillBar = styled.div`
  height: ${(props => props.percent)}%;
  background-color: ${(props => props.color)};
  border-radius: 2em;
`;
const StoreLabel = styled.div`
  position: absolute;
  top: 2.2em;
  right: 0.5em;
  width: 2.3em;
  height: 2.3em;
  background-image: url(${props => props.logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const ValueBar = ({ apk }) => {
  const fillPercent = Math.max(0, Math.min(apk, 1.0)) * 100;
  const getColor = (percentage) => {
    const scale = [
      '#28ff77',
      '#06ff00',
      '#94f50b',
      '#ffde06',
      '#ff9900',
      '#ff6600',
      '#ff0000',
      '#960000',
    ];
    return scale[Math.floor(scale.length*(1 - percentage/100))]
  };
  return (
    <ValueBarWrapper>
      <FillBar percent={fillPercent} color={getColor(fillPercent)}/>
    </ValueBarWrapper>
  )
};

const CategoryTag = ({ category }) => {
  const { value, color } = category;
  return (
    <CategoryTagContainer color={color}>
      {value}
    </CategoryTagContainer>
  )
};

const DrinkResult = ({ data }) => {
  const { name, code, taste, apk, price, url,
    amount, imgUrl, region, category, alcohol, store  } = data;
  const catObj = categories[category] || FALLBACK_CATEGORY;
  const { color } = catObj;
  return (
    <DrinkResultContainer color={color}>
      <ImageWrapper image={imgUrl}>
        <CategoryTag category={catObj}/>
        <ValueBar apk={apk}/>
        <PriceTag>{parseFloat(price).toFixed(2)},-</PriceTag>
        <StoreLabel logo={stores[store].logo}/>
      </ImageWrapper>
      <Description>
        <a href={url} target="_blank">{name}</a>
        <Info>
          <p>{(alcohol*100).toFixed(1)}%</p>
          <p>{amount.toFixed(0)}ml</p>
          <p>{apk.toFixed(2)}eth/kr</p>
        </Info>
        <Taste>{taste}</Taste>
      </Description>
    </DrinkResultContainer>
  );
};

export default DrinkResult;