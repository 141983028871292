import React from 'react';
import styled from 'styled-components';
import {FirstPage as    IconFirst} from '@styled-icons/boxicons-regular/FirstPage';
import {LastPage as     IconLast } from '@styled-icons/boxicons-regular/LastPage';
import {ChevronLeft as  IconPrev } from '@styled-icons/boxicons-regular/ChevronLeft';
import {ChevronRight as IconNext } from '@styled-icons/boxicons-regular/ChevronRight';
import {useSearch} from "./helpers";

const PaginatorContainer = styled.div`
  display: block;
  font-weight: 300;
`;

const PaginatorNavbar =  styled.div`
  display: flex;
  flex-direction: row;
  max-width: 45em;
  min-width: 20em;
  margin: 1em auto 0 auto;
`;

const CurrentPage = styled.div`
  flex: 3;
  text-align: center;
  color: inherit;
  text-decoration: none;
  padding: 0.3em;
`;
const Filler = styled.div`
  flex: 1;
`;
const PageLinkWrapper = styled.button`
  flex: 1;
  border: none;
  background-color: transparent;
  text-align: center;
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  &:hover {
    color: #6082af;
  }
`;
const IconWrapper = styled.div`
  height: 1.4em;
  display: flex;
  color: inherit;
  & > svg {
    width: 1.4em;
    height: 1.4em;
  }
`;

const PageLink = ({ onClick, icon, available, text }) => {

  if (!available) return (
    <Filler />
  );
  return (
    <PageLinkWrapper onClick={onClick}>
      <IconWrapper>
        {icon}
      </IconWrapper>
    </PageLinkWrapper>
  )
};

const Paginator = ({searcher}) => {
  const { searchParams: { page }, setSearchParams, results } = searcher;
  const current = parseInt(page);
  const { hasNextPage, hasPrevPage, totalPages } = results;

  const setPage = (num) => {
    setSearchParams({page: num});
  };

  return (
    <PaginatorContainer>
      <PaginatorNavbar>
        <PageLink
          onClick={() => setPage(1)}
          available={hasPrevPage && current > 2}
          icon={<IconFirst />}
          text={1}
        />
        <PageLink
          onClick={() => setPage(current - 1)}
          available={hasPrevPage}
          icon={<IconPrev />}
          text={current-1}
        />
        <CurrentPage>Side {current} av {totalPages}</CurrentPage>
        <PageLink
          onClick={() => setPage(current + 1)}
          available={hasNextPage}
          icon={<IconNext />}
          text={current+1}
        />
        <PageLink
          onClick={() => setPage(totalPages)}
          available={hasNextPage && current < totalPages - 1}
          icon={<IconLast />}
          text={totalPages}
        />
      </PaginatorNavbar>
    </PaginatorContainer>
  );
};

export default Paginator;