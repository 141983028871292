import React from 'react';
import {CheckBox} from "../common/Inputs";
import {WeakBox} from "../common/Containers";

const Requirements = ({searcher}) => {
  const { searchParams, setSearchParams } = searcher;
  const { onlyinstock } = searchParams;

  const handleCheck = ({ target }) => {
    setSearchParams({[target.name]: !searchParams[target.name]});
  };

  return (
    <WeakBox text="Andre kriterier">
      <table>
        <tbody>
        <tr>
          <td>
            <label htmlFor="checkStock">Må være på lager</label>
          </td>
          <td>
            <CheckBox name="onlyinstock" checked={!!onlyinstock} id="checkStock" onChange={handleCheck} />
          </td>
        </tr>
        </tbody>
      </table>
    </WeakBox>
  );
};

export default Requirements;