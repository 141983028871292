import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  max-width: 50rem;
  min-width: 20rem;
  background-color: white;
  padding: 1.2rem;
  margin: 0.5rem;
  box-shadow: 0 0.1rem 0.3rem rgba(0,0,0,0.3);
`;

const FormBasic = styled.form`
  display: flex;
  flex-direction: column;
  font-weight: 300;
  & input {
    font-size: 1.0em;
    padding: 0.5em;
    margin-bottom: 0.4em;
    border: solid 0.2em lightgray;
    border-radius: 0.4em;
  }
`;

const WeakBoxWrapper = styled.div`
  margin: 0.5em;
  border: solid 0.1em #7da2b7;
  border-radius: 0.3em;
  position: relative;
  & > table {
    margin: 1.2em 0.5em 0.5em 0.5em;
  }
  & p {
    margin: 0.3em 0;
  }
`;

const WeakBoxHeader = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  background-color: white;
  padding: 0.4em;
  top: -1.2em;
  left: 0.5em;
  color: #2f4858;
  & > p {
    margin: 0;
  }
`;

const WeakBox = ({ children, text, icon=null }) => {
  return (
    <WeakBoxWrapper>
      <WeakBoxHeader>
        {text}
        {icon}
      </WeakBoxHeader>
      {children}
    </WeakBoxWrapper>
  );
}

export {
  Card,
  FormBasic,
  WeakBox
}
