import React from 'react';
import styled from 'styled-components';
import {Card, FormBasic} from "../common/Containers";
import {ButtonNormal} from "../common/Buttons";

const LoginWrapper = styled.div`

`;

const Login = () => {
  const handleSubmit = (evt) => {

  };
  return (
    <LoginWrapper>
      <Card>
        <h1>Logg inn</h1>
        <FormBasic onSubmit={handleSubmit}>
          <input required type="text" name="accountName" placeholder="Brukernavn"/>
          <input required type="password" name="accountPassword" placeholder="Passord"/>
          <ButtonNormal type="submit">Logg inn</ButtonNormal>
        </FormBasic>
      </Card>
    </LoginWrapper>
  )
};

export default Login;