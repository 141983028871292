import React from 'react';
import styled from 'styled-components';
import { Check } from '@styled-icons/fa-solid/Check';

const CheckboxWrapper = styled.label`
  border: solid 0.2em #dedede;
  border-radius: 0.3em;
  cursor: pointer;
  margin: 0 0.2em;
  display: inline-block;
  position: relative;
  &:hover {
    background-color: #f3f3f3;
  }
  & > div {
    opacity: 0;
    padding: 0;
    color: #DC4261;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
  }
  & > input {
    display: none;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  & > input:checked + div {
    opacity: 1;
    visibility: visible;
  }
`;

const IcnCheck = styled(Check)`
  height: 1.0em;
  color: inherit;
  transform: scale(1.5) translateX(0.1em) translateY(-0.1em);
  &:hover {
    transform: scale(1.7) translateX(0.1em) translateY(-0.1em);
  }
`;


const CheckBox = ({checked, name, onChange, id}) => {
  return (
    <CheckboxWrapper htmlFor={id}>
      <input type="checkbox" id={id} name={name} checked={checked} onChange={onChange} />
      <div>
        <IcnCheck />
      </div>
    </CheckboxWrapper>
  )
}

const TextInput = styled.input`
  border: solid 0.1em lightgrey;
  padding: 0.54em;
  font-size: 1.0em;
  font-weight: 300;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.2em;
`;

const StyledRange = styled.input`
  -webkit-appearance: none;
  margin: 0.5em 0;
  width: 100%;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5em;
    margin: 0.45em 0;
    cursor: pointer;
    background-color: #446880;
    border-radius: 0.5em;
    border: none;
  }
  &::-moz-range-track {
    width: 100%;
    height: 0.5em;
    margin: 0.45em 0;
    cursor: pointer;
    background-color: #446880;
    border-radius: 0.5em;
    border: none;
  }
  &::-ms-track {
    width: 100%;
    height: 0.5em;
    margin: 0.45em 0;
    cursor: pointer;
    border: none;
    color: transparent;
  }
  &:focus::-webkit-slider-runnable-track {
    background: #537c9b;
  }
  &:focus::-moz-range-track {
    background: #537c9b;
  }
  &:focus::-ms-track {
    background: #537c9b;
  }
  &::-webkit-slider-thumb {
    box-shadow: 0 0.1em 0.2em rgba(0,0,0,0.4);
    border: none;
    height: 1.4em;
    width: 1.4em;
    border-radius: 2em;
    background-color: #DC4261;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -0.5em;
    transition: 0.08s linear;
  }
  &::-moz-range-thumb {
    box-shadow: 0 0.1em 0.2em rgba(0,0,0,0.4);
    border: none;
    height: 1.4em;
    width: 1.4em;
    border-radius: 2em;
    background-color: #DC4261;
    cursor: pointer;
    transition: 0.08s linear;
  }
  &::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    border: none;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    transition: 0.08s linear;
  }
  &::-webkit-slider-thumb:hover {
    transform: scale(1.2);  
  }
  &::-moz-range-thumb:hover {
    transform: scale(1.2);
  }
  &::-ms-thumb:hover {
    transform: scale(1.2);
  }
`;

const Range = ({id, min, max, value, onChange, name}) => {
  return (
    <StyledRange type="range" id={id} min={min} max={max} value={value} onChange={onChange} name={name} />
  );
}
const Select = styled.select`
  & *, & *::before, & *::after {
    box-sizing: border-box;
  }
  &::-ms-expand {
    display: none;
  }
  appearance: none;
  background: white;
  color: inherit;
  font-family: inherit;
  margin: 0.5em 0;
  border-radius: 0.2em;
  font-size: 1.0em;
  overflow: hidden;
  border: solid #DC4261;
  border-width: 0.15em 0.15em 0.3em 0.15em;
  cursor: pointer;
  font-weight: 300;
  padding: 0.2em 0.6em;
  outline: none;
  &:hover {
    background: #f5f5f5;
  }
`;

export {CheckBox, Range, TextInput, Select};