import React, { useState }  from 'react';
import styled from 'styled-components';
import api from '../../api';
import {ButtonNormal} from "../common/Buttons";
import { Trash } from '@styled-icons/boxicons-regular/Trash';

const DeleteAllButtonContainer = styled.div`
  
`;

const Button = styled(ButtonNormal)`
  color: darkred;
  background-color: #db6f79;
  display: flex;
  flex-direction: row;
  transition: 0.1s;
  width: 100%;
  box-shadow: ${(props) => props.glow ? '0 0 1.0rem #f47d87' : 'none'};
  &:hover {
    background-color: #f47d87;
  }
  & > p {
    flex: 1;
    text-align: center;
    margin: 0;
    max-width: 16rem;
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content:center;
  }
`;

const Icon = styled(Trash)`
  color: darkred;
  height: 1.0rem;
  margin: 0 0.5rem 0 0;
`;

const DeleteAllButton = () => {

  const [ verified, setVerified ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const handleClick = async () => {
    if (verified) {
      try {
        const payload = { verified: true };
        setLoading(true);
        await api.deleteAll(payload).then(res => {
          const { data } = res;
          if (data.success) {
            // TOOD: Dispatch confirmation message
          }
        })
      } catch (err) {
        // TODO: Dispatch error w/Redux or some shit
        console.log(err.message);
      }
      setLoading(false);
      setVerified(false);
    } else {
      setVerified(true);
    }
  };

  return (
    <DeleteAllButtonContainer>
      <Button onClick={handleClick} glow={verified}>
        <div>
          <Icon />
        </div>
        <p>
          {
            verified ? 'Are you sure? This cannot be undone! Click again to delete all drinks.' :
            loading ? 'Deleting ...' :
              'Delete all drinks'
          }
        </p>
      </Button>
    </DeleteAllButtonContainer>
  );
};

export default DeleteAllButton;