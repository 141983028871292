import React, { useState, useEffect } from 'react';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import api from '../../api'
import DrinkResult from './DrinkResult';
import Paginator from './Paginator';
import Sorter from "./Sorter";
import SearchForm from "./SearchForm";
import queryString from 'query-string';
import DrinkResults from './DrinkResults';
import {useSearch, device} from "./helpers";

const DrinkSearchContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`;

const SearchSeparator = styled.section`
  display: flex;
  flex-direction: row;
  max-width: 80em;
  width: 100%;
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
const LeftPane = styled.div`
  width: 18em;
  box-sizing: content-box;
  @media ${device.mobileL} {
    width: 100%; 
  }
`;
const RightPane = styled.div`
  flex: 1;
`;

const ResultsHeader = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 0 0.3em 0.3em 0;
  margin: 0.5em 0.5em 0 0;
  box-shadow: 0.2em 0.1em 0.2em rgba(0,0,0,0.2);
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: center;
  }
`;

const ResultsInfo = styled.div`
  text-align: center;
  flex: 1;
  font-weight: 300;
  color: dimgray;
  font-size: 1.0em;
  font-style: italic;
  margin: 0;
  padding: 0 0.5em;
`;

const DrinkSearch = () => {
  const searcher = useSearch();
  const { loading, results } = searcher;
  return (
    <DrinkSearchContainer>
      <SearchSeparator>
        <LeftPane>
          <SearchForm searcher={searcher}/>
        </LeftPane>
        <RightPane>
          <ResultsHeader>
            <ResultsInfo>
              { !loading ? (
                <p>
                  Viser {results.docs.length} av {results.totalDocs} resultat
                  {results.totalDocs !== 1 ? 'er' : ''}.
                </p>
              ) : null }
            </ResultsInfo>
            <Sorter searcher={searcher}/>
          </ResultsHeader>
          <DrinkResults searcher={searcher}/>
        </RightPane>
      </SearchSeparator>
      <Paginator searcher={searcher}/>
    </DrinkSearchContainer>
  );
};

export default DrinkSearch;