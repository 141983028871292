import styled from 'styled-components';

const Article = styled.article`
  max-width: 40em;
  color: #2b2b2b;
  & > p {
    font-weight: 300;
    margin: 1.2em 0;
    line-height: 180%;
  }
  & > h1 {
    font-size: 2.4em;
    text-align: center;
    padding: 0 0 0.5em 0;
    border-bottom: solid 1px #e9e9e9;
  }
  & h1, & h2, & h3 {
    margin: 0;
  }
`;

const NormalLink = styled.a`
  text-decoration: none;
  color: #DC4261;
  padding: 0.1em 0.4em;
  background-color: #ffdde3;
  display: inline-block;
  border-radius: 0.2em;
`;

export {
    Article,
  NormalLink
}