import React, {useState} from 'react';
import styled from 'styled-components';
import qr from '../../res/vipps/qr.png';
import { ArrowDropDown } from '@styled-icons/material-outlined/ArrowDropDown';

const VippsButtonWrapper = styled.div`
  background-color: #ff6633;
  border-radius: 0.4em;
  margin: 1em;
  color: white;
  border: solid 0.15em white;
  box-shadow: 0 0.1em 0.3em rgb(0, 0, 0, 0.3);

  transition: 0.1s;
  &:hover {
    box-shadow: 0 0 0.7em rgb(255,79,0);
    
  }
`;
const VippsHeader = styled.div`
  padding: 0.4em;
  display: flex;
    cursor: pointer;
  & > p {
    flex: 1;
    text-align: center;
    font-size: 1.1em;
    font-weight: 300;
    margin: 0;
  }
`;
const Expandable = styled.div`
  display: flex;
  transition: 1s ease;
  height: ${props => props.expanded ? '24em' : '0'};
  overflow: hidden;
  justify-content: center;
`;
const DropDownIcon = styled(ArrowDropDown)`
  color: white;
  transition: 0.8s ease;
  height: 2em;
  transform: rotate(${props => props.expanded ? '180deg' : '0deg'});
`;

const ExpandableContent = styled.div`
  border-top: solid 0.1em white;
  padding: 1em 0.5em;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const VippsQR = styled.div`
  flex: 1;
  width: 100%;
  background-image: url(${props => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
const PhoneNumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    display: inline;
    font-size: 1.2em;
  }
`;
const PhoneNumber = styled.p`
  font-size: 2.5em;
  font-weight: bold;
  margin: 0.5em;
`;

const VippsButton = () => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <VippsButtonWrapper>
      <VippsHeader onClick={handleClick}>
        <p>Doner med vipps</p>
        <DropDownIcon expanded={expanded}/>
      </VippsHeader>
      <Expandable expanded={expanded}>
        <ExpandableContent>
          <VippsQR image={qr}/>
          <PhoneNumberWrapper>
            <span>Tlf</span>
            <PhoneNumber>93 67 86 07</PhoneNumber>
          </PhoneNumberWrapper>
        </ExpandableContent>
      </Expandable>
    </VippsButtonWrapper>
  )
};

export default VippsButton;