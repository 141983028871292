import React, { useState } from 'react';
import styled from 'styled-components';
import { Card } from '../common/Containers.jsx';
import api from "../../api";
import { ButtonNormal } from '../common/Buttons';

const NewDrinksContainer = styled.div`
  
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputFile = styled.input`
  padding: 4rem;
  margin: 1rem 0;
  border: dashed #8c82b1 0.2rem;
  border-radius: 1.2rem;
`;

const NewDrinks = () => {
  const [ uploading, setUploading ] = useState(false);
  const [ file, setFile ] = useState(null);

  const handleInput = (evt) => {
    setFile(evt.target.files[0]);
  };

  const handleSubmit = async (evt) => {
    const payload = file;
    setUploading(true);
    await api.insertDrinks(payload).then(res => {
      const { data } = res;
      if (data.success) {
        console.log(data);
      } else {
        // TODO: Dispatch error w/Redux or some shit
        console.log(data.message);
      }
      setUploading(false);
    })
  };

  if (uploading) return (
    <NewDrinksContainer>
      <Card>
        <p><i>Uploading ...</i></p>
      </Card>
    </NewDrinksContainer>
  );

  return (
    <NewDrinksContainer>
      <Card>
        <StyledForm onSubmit={handleSubmit}>
          <label htmlFor="inpFile">Upload a JSON file</label>
          <InputFile type="file" name="inpFile" id="inpFile" required onInput={handleInput} />
          <ButtonNormal type="submit">Submit data</ButtonNormal>
        </StyledForm>
      </Card>
    </NewDrinksContainer>
  );
};

export default NewDrinks;