import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import api from "../../api";
import {Redirect} from "react-router-dom";
import DrinkResult from "./DrinkResult";
import {device, translateValues, useSearch} from "./helpers";
import queryString from 'query-string';
import LoadingIcon from "../common/LoadingIcon";

const ResultsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  
`;

const DrinkResults = ({searcher}) => {
  const { loading, results } = searcher;

  if (loading) return (
    <ResultsContainer>
      <LoadingIcon />
    </ResultsContainer>
  );

  if (!results.docs) return (
    <ResultsContainer>
      <p>Ingen søk matchet resultatet.</p>
    </ResultsContainer>
  );

  return (
    <ResultsContainer>
      { results.docs ?
        results.docs.map( (drink) => <DrinkResult key={drink._id} data={drink}/>) :
        <p>Ingen produkter passer søkekriteriene.</p>
      }
    </ResultsContainer>
  );
};


export default DrinkResults;
export { ResultsContainer };