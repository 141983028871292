import React from 'react';
import styled from 'styled-components';
import DeleteAllButton from './DeleteAllButton'
import {Card} from "../common/Containers";
import {WineBottle as IconBottle} from "@styled-icons/fa-solid/WineBottle";
import {Upload as IconUpload} from "@styled-icons/boxicons-regular/Upload";
import {Link} from "react-router-dom";

const ControlPanelContainer = styled.div`
  
`;
const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  height: 1.0rem;
  width: 1.0rem;
  margin: 0 0.4rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: inherit;
  & > svg {
    color: inherit
  }
`;

const AdminLinkWrapper = styled(Link)`
  padding: 0.3rem;
  font-size: 0.9rem;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  border: solid 0.2em lightgray;
  border-radius: 0.4rem;
  margin-bottom: 0.4em;
  color: inherit;
  & > p {
    text-align: center;  
    flex: 1;
    margin: 0;
  }
  &:hover {
    background-color: #ffffff;
    color: #1d1d1d;
  }  
`;

const AdminLink = ({to, text, icon }) => {
  return (
    <AdminLinkWrapper to={to}>
      <IconWrapper>
        {icon}
      </IconWrapper>
      <p>{text}</p>
    </AdminLinkWrapper>
  );
};

const ControlPanel = () => {
  return (
    <ControlPanelContainer>
      <Card>
        <h1>Kontrollpanel</h1>
        <OptionsContainer>
          <AdminLink to="/new-single" text="Legg til produkt" icon={<IconBottle />} />
          <AdminLink to="/new-multiple" text="Last opp JSON" icon={<IconUpload />} />
          <DeleteAllButton />
        </OptionsContainer>
      </Card>

    </ControlPanelContainer>
  );
};

export default ControlPanel;