import React, {useState} from 'react';
import styled from 'styled-components';
import {ArrowDropDown} from "@styled-icons/material/ArrowDropDown";
import {ArrowDropUp} from "@styled-icons/material/ArrowDropUp";
import { Cross } from '@styled-icons/icomoon/Cross';
import categories from "../../models/categories";
import {useSearch} from "./helpers";

const CategoryFilterWrapper = styled.div`
  position: relative;
  z-index: 100;
`;

const CategoriesDropdown = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  top: calc(100% - 0.1em);
  background-color: white;
  border: solid lightgray;
  border-width: 0 0.1em 0.1em 0.1em;
  width: 100%;
  border-radius: 0 0 0.2em 0.2em;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 0.15em 0.15em rgba(0,0,0,0.2);
`;

const CategoryOption = styled.div`
  cursor: pointer;
  padding: 0.4em 0.8em;
  flex: 1;
  box-sizing: border-box;  
  border: solid 0.1em #efefef;
  min-width: 49%;
  font-weight: 300;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const CategoryFilterContent = styled.div`
  display: flex;
  flex-direction: row;
  border: solid 0.1em lightgrey;
  
  font-size: 1.0em;
  font-weight: 300;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.2em;
  & p {
    margin: 0;
  }
`;
const Labels = styled.div`
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  padding: 0.54em;
`;
const Label = styled.div`
  border-radius: 0.4em;
  font-size: 0.9em;
  background-color: #bfd6ee;
  border: solid 0.1em #93a6b7;
  color: #64717c;
  padding: 0.2em;
  margin: 0.1em 0.2em 0.1em 0;
  display: flex;
  flex-direction: row;
  & > p {
    margin: 0 0.5em;
    flex: 1;
  }
  & > div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  & > div:hover {
    color: black; 
  }
`;
const DropdownButton = styled.div`
  border: none;
  padding: 0 0.8em;
  display: flex;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border-left: solid 0.1em lightgrey;
  color: dimgray;
  &:hover {
    color: black;
  }
`;

const IconExpand = styled(ArrowDropDown)`
  height: 1.7em;
  color: inherit;
`;
const IconCollapse = styled(ArrowDropUp)`
  height: 1.7em;
  color: inherit;
`;
const IconRemove = styled(Cross)`
  width: 0.8em;
  height: 0.8em;
  color: inherit;
`;
const PlaceHolder = styled.p`
  color: dimgray;
  font-weight: 300;
  font-style: italic;
`

const usePicks = (searcher) => {
  const { searchParams, setSearchParams } = searcher;
  return {
    picks: searchParams.categories,
    handleToggle: (categoryName) => {
      let np;
      if (searchParams.categories.includes(categoryName)) {
        np = searchParams.categories.filter(p => p !== categoryName);
      } else {
        np = [...searchParams.categories, categoryName];
      }
      setSearchParams({['categories']: np});
    }
  };
};

const CategoryFilter = ({searcher}) => {
  const [ collapsed, setCollapsed ] = useState(true);
  const { picks, handleToggle } = usePicks(searcher);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <CategoryFilterWrapper onClick={() => {
      if (!picks.length && collapsed) {handleCollapse()}}
    }>
      <p>Velg kategorier</p>
      <CategoryFilterContent>
        <Labels>
          { picks.length === 0 ? (
            <PlaceHolder>Any category</PlaceHolder>
          ) : picks.map((p, i) => (
            <Label key={i}>
              <p>{categories[p].value}</p>
              <div onClick={() => handleToggle(p)} >
                <IconRemove />
              </div>
            </Label>
          ))}
        </Labels>
        <DropdownButton onClick={handleCollapse}>
          { collapsed ? <IconExpand /> : <IconCollapse /> }
        </DropdownButton>
      </CategoryFilterContent>
      { collapsed ? null : (
        <CategoriesDropdown>
          { Object.entries(categories)
            .sort(([keyA, valA], [keyB, valB]) => {
              return keyA > keyB;
            })
            .map(([name, cat]) => (
            <CategoryOption key={name} onClick={() => handleToggle(cat.name)}>
              {cat.value}
            </CategoryOption>
          ))}
        </CategoriesDropdown>
      )}
    </CategoryFilterWrapper>
  );
};
export default CategoryFilter;