import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import api from "../api";

const ApiTestContainer = styled.div`
  
`;

const ApiTest = () => {
  const [ loaded, setLoaded ] = useState(false);
  const [ response, setResponse ] = useState('');

  useEffect(() => {
    const testApi = async () => {
      await api.getHello().then((res) => {
        const { data } = res;
        if (data.success) {
          setResponse(data.data);
        } else {
          setResponse(res.statusText);
        }
      });
      setLoaded(true);
    };
    testApi();
  }, []);
  return (
    <ApiTestContainer>
      { loaded ? (
        <p>{response}</p>
      ) : (
        <p><i>Loading ... </i></p>
      )}
    </ApiTestContainer>
  );
};

export default ApiTest;