import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import DrinkResults, {ResultsContainer} from '../DrinkSearch/DrinkResults';
import categories from "../../models/categories";
import queryString from "query-string";
import api from "../../api";
import DrinkResult from "../DrinkSearch/DrinkResult";
import LoadingIcon from "../common/LoadingIcon";
import stores from "../../models/stores";

const LeaderboardWrapper = styled.div`
  max-width: 80em;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ResultsWrapper = styled.div`
  flex: 1;
`;

const CategoryPickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.7em;
`;
const SingleCategory = styled.label`
  flex-grow: 1;
  min-width: 12%;
  max-width: 25%;
  margin: 0.4em;
  cursor: pointer;
  border-radius: 0.4em;
  overflow: hidden;
  box-shadow: ${(props) => props.active ? '0 0 0.4em ' + props.color : '0 0.1em 0.2em rgba(0,0,0,0.4)'} ;
  background-color: ${(props) => props.color};
  border: solid 0.2em ${(props) => props.active ? 'white' : 'transparent'};
  transition: 0.1s;
  color: white;
  & > p {
    padding: 0.7em 1.0em;
    text-align: center;
    margin: 0;  
  }
  &:hover {
    box-shadow: 0 0 0.8em ${(props) => props.color};
  }
`;

const CategoryPicker = ({categoryHook}) => {
  const { category, setCategory } = categoryHook;
  return (
    <CategoryPickerWrapper>
      <SingleCategory onClick={() => setCategory(null)} active={!category} color="#34437A">
        <p>Alle kategorier</p>
      </SingleCategory>
      { Object.entries(categories).map( ([name, cat]) => (
        <SingleCategory key={name} onClick={() => setCategory(name)} active={name === category} color={cat.color}>
          <p>{cat.value}</p>
        </SingleCategory>
      ))}
    </CategoryPickerWrapper>
  )
};

const VendorPickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0.7em;
`;

const SingleVendor = styled.div`
  flex: 1;
  display: flex;
  margin: 0.5em;
  padding: 0.5em;
  background-color: white;
  border-radius: 0.4em;
  box-shadow: 0 0.1em 0.2em rgba(0,0,0,0.4);
  opacity: ${(props) => props.active ? '1' : '0.4'};
  cursor: pointer;
  align-items: center;
  transition: 0.2s;
  & > p {
    flex: 1;
    margin: 0;
    text-align: center;
  }
  & > img {
    height: 1.5em;
  }
`;

const VendorPicker = ({categoryHook}) => {
  const { vendor, setVendor } = categoryHook;
  return (
    <VendorPickerWrapper>
      { Object.values(stores)
        .filter(store => store.name !== 'ukjent')
        .map(store => (
        <SingleVendor onClick={() => setVendor(store)} active={vendor === store}>
          <img src={store.logo} alt=""/>
          <p>{store.value}</p>
        </SingleVendor>
      ))}
      <SingleVendor active={vendor === null}
        onClick={() => setVendor(null)}>
        <p>Begge</p>
      </SingleVendor>
    </VendorPickerWrapper>
  )
};

const Results = ({categoryHook}) => {

  const resultsDefault = {
    docs: []
  };
  const { category, vendor } = categoryHook;
  const [ loading, setLoading ] = useState(false);
  const [ results, setResults ] = useState([]);

  useEffect(() => {
    const getResults = async () => {
      setLoading(true);
      try {
        const query = {
          name: "",
          sortBy: "apk",
          sortDir: "desc",
          priceMin: 0,
          priceMax: Infinity,
          categories: [category],
          stores: vendor !== null ? [vendor.name] : [],
          onlyinstock: true,
        };
        await api.getDrinksPage(1, queryString.stringify(query))
          .then((res) => {
            const { data } = res;
            if (data.success) {
              setResults(data.data);
            } else {
              setResults(resultsDefault);
            }
          });
      } catch (e) {
        setResults(resultsDefault);
        // TODO: Dispatch error
      }
      setLoading(false);
    };
    getResults();
  }, [category, vendor])

  if (loading) return (
    <ResultsContainer>
      <LoadingIcon />
    </ResultsContainer>
  );

  if (!results.docs) return (
    <ResultsContainer>
      <p>Fant ingen resultater for den kategorien!</p>
    </ResultsContainer>
  );

  return (
    <ResultsContainer>
      { results.docs.map( (drink) => (
        <DrinkResult key={drink._id} data={drink}/>
      ))}
    </ResultsContainer>
  );
};

const useCategory = () => {
  const [ category, setCategory ] = useState('');
  const [ vendor, setVendor ] = useState(stores.vinmonopolet);
  return {
    category,
    setCategory,
    vendor,
    setVendor
  }
}

const Leaderboard = () => {
  const categoryHook = useCategory();
  return (
    <LeaderboardWrapper>
      <CategoryPicker categoryHook={categoryHook}/>
      <VendorPicker categoryHook={categoryHook}/>
      <Results categoryHook={categoryHook}/>
    </LeaderboardWrapper>
  );
};

export default Leaderboard;