import React, { useState } from 'react';
import styled from 'styled-components';
import { Card } from '../common/Containers';
import api from '../../api';
import {ButtonNormal} from "../common/Buttons";
import categories from '../../models/categories';

const NewDrinkContainer = styled.div`
  & table {
    width: 100%;
  }
  & tr input, tr select {
    width: 100%;
  }
`;

/*
  name: {type: String, required: true },
  code: { type: String, required: true },
  apk: { type: Number, required: false },
  taste: { type: String , required: false },
  price: { type: Number, required: true },
  url: { type: String, required: true },
  amount: { type: Number, required: false },
  img_url: { type: String, required: false },
  region: { type: String, required: false },
  alcohol: { type: Number, required: false },
 */
// TODO: Add regex checkers
const formElements = [
  {name: 'name',    label:'Name',     type: 'text',   required: true,  min:0, max: Infinity, step:0.1, placeHolder:'Drink name'},
  {name: 'code',    label:'Code',     type: 'number', required: true,  min:0, max: Infinity, step:1,   placeHolder:'Code identifier'},
  {name: 'taste',   label:'Taste',    type: 'text',   required: true,  min:0, max: Infinity, step:0.1, placeHolder:'Taste description'},
  {name: 'price',   label:'Price',    type: 'number', required: true,  min:0, max: Infinity, step:0.01,placeHolder:'NOK'},
  {name: 'url',     label:'URL',      type: 'text',   required: true,  min:0, max: Infinity, step:0.1, placeHolder:'http://...'},
  {name: 'amount',  label:'Amount',   type: 'number', required: true,  min:0, max: Infinity, step:1,   placeHolder:'Millilitres'},
  {name: 'imgUrl', label:'Image URL', type: 'text',   required: true,  min:0, max: Infinity, step:0.1, placeHolder:'http://foo.bar.jpg'},
  {name: 'region',  label:'Region',   type: 'text',   required: true,  min:0, max: Infinity, step:0.1, placeHolder:'Country'},
  {name: 'alcohol', label:'Vol %',     type: 'number',  required: true,  min:0, max: 100,      step:0.1, placeHolder:'4.5'},
];

const NewDrink = () => {

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const { target: { elements } } = evt;
    const { name, category, code, taste, price, url, amount, imgUrl, region, alcohol } = elements;

    const payload = {
      name: name.value,
      category: category.value,
      code: code.value,
      taste: taste.value,
      price: price.value,
      url: url.value,
      amount: amount.value,
      imgUrl: imgUrl.value,
      region: region.value,
      alcohol: alcohol.value,
    };

    await api.insertDrink(payload).then(res => {
      const { data } = res;
      if (data.success) {
        console.log('Inserted a new drink.');
      } else {
        // TODO: Dispatch error w/Redux or some shit
        console.log(data.message);
      }
    })
  };

  return (
    <NewDrinkContainer>
      <Card>
        <form onSubmit={handleSubmit}>

          <table>
            <tbody>
            <tr>
              <td>Kategori</td>
              <td>
                <select name="inpCategory" id="inpCategory">
                  { Object.entries(categories).map(([name, cat]) => (
                    <option value={name}>{cat.value}</option>
                  ))}
                </select>
              </td>
            </tr>
            { formElements.map((f) => (
              <tr key={f.name}>
                <td>
                  <label htmlFor={`inp${f.name}`}>
                    {f.label}
                  </label>
                </td>
                <td>
                  <input
                    type={f.type}
                    name={f.name}
                    id={`inp${f.name}`}
                    placeholder={f.placeHolder}
                    required={f.required}
                    min={f.min}
                    max={f.max}
                    step={f.step}
                  />
                </td>
              </tr>
             ))}
            </tbody>
          </table>
          <ButtonNormal type="submit">Submit</ButtonNormal>
        </form>
      </Card>
    </NewDrinkContainer>
  );
};

export default NewDrink;