import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  background-color: white;
  box-shadow: 0 -0.1em 0.2em rgba(0, 0, 0, 0.1);
  padding: 1rem;
  & > a {
    text-align: center;
    color: dimgray;
    text-decoration: none;
    display: block;
  }
  & > a:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <a target="_blank" href="https://eliasv.com">Laget av Elias B. Vågan 2020</a>
      <p></p>
    </FooterContainer>
  );
};

export default Footer;